// eslint-disable-next-line import/no-extraneous-dependencies
import { useForm, useFormAutoSubmit } from '@graphcommerce/react-hook-form'
import { i18n } from '@lingui/core'
import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'
import { Icon } from '../Layout/Icon'

export type SearchFormProps = {
  totalResults?: number
  search?: string
  urlHandle?: string
  autoFocus?: boolean
  className?: string
  containerClassName?: string
}

export function SearchForm(props: SearchFormProps) {
  const searchInputElement = useRef<HTMLInputElement>(null)

  useEffect(() => {
    searchInputElement.current?.focus()
  }, [])

  const { search = '', urlHandle = 'search', className, containerClassName } = props
  const router = useRouter()

  const form = useForm({ mode: 'onSubmit', defaultValues: { search } })
  const { handleSubmit, register, reset, getValues } = form

  const submit = handleSubmit((formData) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    router.replace(`/${urlHandle}/${formData.search}`)
    reset(getValues())
  })
  useFormAutoSubmit({ form, submit })

  return (
    <form
      className='flex w-full max-w-[350px] items-center rounded-[5px] border-[1px] border-50-grey bg-5-grey bg-[#0000006] px-[14px]'
      onSubmit={submit}
    >
      <Icon name='search' className='cursor-pointer text-3xl text-50-grey' onClick={submit} />
      <input
        type='text'
        className='h-[36px] max-h-[36px] w-full border-none bg-transparent text-xl text-tight-black outline-none focus:outline-0'
        placeholder={i18n._(/* i18n */ 'Search...')}
        {...register('search', { required: true, minLength: 2 })}
      />
    </form>
  )
}
