import { assertFormGqlOperation, UseFormReturn } from '@graphcommerce/react-hook-form'
import { Trans } from '@lingui/react'
import { StandardTextField } from '../../TextInput/TextFieldStyles'

type NameFieldValues = {
  firstname?: string
  lastname?: string
  prefix?: string
}

type NameFieldProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any>
  readOnly?: boolean
  prefix?: boolean
  prefixes?: string[]
  required?: boolean
  isFullWidth?: boolean
  noPadding?: boolean
  columnDisplay?: boolean
  className?: string
}

export function NameFields(props: NameFieldProps) {
  const {
    form,
    readOnly,
    required,
    isFullWidth,
    noPadding,
    columnDisplay = false,
    className,
  } = props
  assertFormGqlOperation<NameFieldValues>(form)

  const { formState, register, valid } = form

  return (
    <div
      className={`flex w-full flex-col gap-x-5 ${
        columnDisplay ? 'gap-y-6' : 'gap-y-5 md:flex-row'
      } ${className}`}
    >
      <StandardTextField
        label={<Trans id='First Name' />}
        variant='outlined'
        disabled={readOnly}
        error={!!formState.errors.firstname}
        required={required}
        {...register('firstname', {
          required: true,
        })}
        className='min-w-[260px]'
        size='small'
        sx={{
          width: '260px',
          minWidth: '260px',
        }}
      />
      <StandardTextField
        label={<Trans id='Last Name' />}
        variant='outlined'
        disabled={readOnly}
        required={required}
        error={!!formState.errors.lastname}
        {...register('lastname', {
          required: true,
        })}
        className='min-w-[260px]'
        size='small'
        sx={{
          width: '260px',
          minWidth: '260px',
        }}
      />
    </div>
  )
}
