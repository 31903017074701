import {
  useCustomerQuery,
  useCustomerSession,
  UseCustomerSessionReturn,
} from '@graphcommerce/magento-customer/hooks'
import { Trans } from '@lingui/react'
import { NoSsr } from '@mui/material'
import Link from 'next/link'
import { AccountOrderItemsDocument } from './AccountOrderItems.gql'

type OrdersFabContentProps = {
  session?: UseCustomerSessionReturn
}

function OrdersFabContent(props: OrdersFabContentProps) {
  const { session } = props

  const orders = useCustomerQuery(AccountOrderItemsDocument, {
    fetchPolicy: 'cache-and-network',
    variables: {
      pageSize: 5000,
      currentPage: 1,
    },
  })

  const activeOrderCount =
    orders.data?.customer?.orders?.items?.filter(
      (item) =>
        item?.status?.toUpperCase() !== 'CANCELED' &&
        item?.status?.toUpperCase() !== 'ORDER SHIPPED' &&
        item?.status?.toUpperCase() !== 'COMPLETE',
    )?.length ?? 0

  if (session?.loggedIn) {
    return (
      <div className='relative'>
        <Link href='/account'>
          <div className='flex h-[35px] min-w-[121px] items-center justify-center gap-x-[12px] rounded-[20px] border-concept-print-orange-hover bg-pure-white pl-[8px] pr-0'>
            <p className='Type-Large-Medium font-roboto text-black'>
              <Trans id='My Orders' />
            </p>
            {activeOrderCount > 0 && (
              <div className='flex h-[25px] w-[25px] items-center justify-center rounded-[50%] bg-material-ui-blue'>
                <span className='text-center text-[12px] font-bold text-pure-white'>
                  {activeOrderCount}
                </span>
              </div>
            )}
            {/* <div className='absolute top-0 flex h-full w-full items-center justify-center' /> */}
          </div>
        </Link>
      </div>
    )
  }

  return null
}

export type OrdersFabProps = Omit<OrdersFabContentProps, 'session'>

export function OrdersFab(props: OrdersFabProps) {
  const session = useCustomerSession()

  return (
    <NoSsr fallback={<OrdersFabContent {...props} />}>
      <OrdersFabContent session={session} {...props} />
    </NoSsr>
  )
}
