import { Image } from '@graphcommerce/image'
import { TabContext, TabPanel } from '@mui/lab'
import { Tab, Tabs, styled } from '@mui/material'
import { useRouter } from 'next/router'
import { Dispatch, ReactElement, SetStateAction, useState } from 'react'
import missingImageIcon from '../../assets/images/missing-image-icon.svg'

import { SubCategoryTab } from './SubCategoryTab'

type SubHeaderTabsProps = {
  menuItems?: any
  className?: string
  url?: string
  setShowMenu: Dispatch<SetStateAction<boolean>>
}

export interface VerticalTabProps {
  label: string
  id: string
  value: string
  'aria-controls': string
  onHover?: () => void
  icon?: ReactElement<any>
  onClick?: () => void
}

export const VerticalTab = styled((props: VerticalTabProps) => (
  <Tab
    disableRipple
    {...props}
    onMouseEnter={props.onHover}
    icon={props.icon}
    onClick={() => props.onClick && props?.onClick()}
  />
))(() => ({
  whiteSpace: 'nowrap',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyItems: 'start',
  justifyContent: 'start',
  gap: 20,
  marginBottom: 0,
  minHeight: 56,
  color: 'rgba(0, 0, 0, 0.6)',
  'img.MuiTab-iconWrapper': {
    marginBottom: 0,
    height: 30,
    width: 30,
  },
  ':hover': {
    backgroundColor: 'rgba(97,51,148,0.1)',
  },
}))

export function SubHeaderTabs(props: SubHeaderTabsProps) {
  const { menuItems, className, setShowMenu } = props
  const [value, setValue] = useState('0')
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }
  const a11yProps = (index: number) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  })

  const router = useRouter()

  const handleClickParentCategory = async (url: string) => {
    await router.push(`/${url}`)
    setShowMenu(false)
  }

  return (
    <div
      onMouseEnter={() => setShowMenu(true)}
      onMouseLeave={() => setShowMenu(false)}
      className={`grid shadow-[20px] lg:grid-cols-[30fr_70fr] xl:grid-cols-[22fr_78fr] ${className}`}
    >
      <TabContext value={value}>
        <div className='h-full w-full'>
          <Tabs
            value={value}
            onChange={handleChange}
            orientation='vertical'
            variant='scrollable'
            scrollButtons='auto'
            sx={{
              borderRight: 1,
              borderColor: 'divider',
              width: '100%',
              display: 'flex',
              marginTop: 0,
              maxHeight: '90vh',
            }}
          >
            {menuItems
              ?.filter((item) => item?.include_in_menu)
              .map((category, index) => (
                <VerticalTab
                  label={category.name}
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                  onHover={() => setValue(index?.toString())}
                  icon={
                    category?.thumbnail ?? category?.image ? (
                      <Image
                        unoptimized
                        src={category?.thumbnail ?? category?.image}
                        alt={category?.thumbnail_alt ?? category?.image_alt}
                        height={30}
                        width={30}
                        className='flex h-[30px] w-[30px] items-center justify-center rounded-[50%]'
                        layout='fixed'
                      />
                    ) : (
                      <Image
                        unoptimized
                        src={missingImageIcon}
                        height={30}
                        width={30}
                        className='flex h-[30px] w-[30px] items-center justify-center rounded-[50%]'
                      />
                    )
                  }
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                  {...a11yProps(index)}
                  onClick={() => handleClickParentCategory(category.url_path as string)}
                  value={index.toString()}
                />
              ))}
          </Tabs>
        </div>
        <div className='h-full'>
          {menuItems
            .filter((item) => item?.include_in_menu)
            .map((item, index) => (
              <TabPanel value={index.toString()}>
                <div>
                  <SubCategoryTab
                    menuItems={item?.children}
                    url={item?.url_path}
                    setShowMenu={setShowMenu}
                  />
                </div>
              </TabPanel>
            ))}
        </div>
      </TabContext>
    </div>
  )
}
