import { useCallback } from "react";
import { useQuery } from "@graphcommerce/graphql";
import { SocialSignInsDocument } from "./SocialSignIns.gql";
import { SignInGoogle } from "./SignInGoogle";

export type Social = {
  label?: string | null | undefined;
  type?: string | null | undefined;
  url?: string | null | undefined;
}

export function SignInSocial() {
  const { data, loading } = useQuery(SocialSignInsDocument);

  const getSocialComponent = useCallback((socialPlatform: Social) => {
    if (socialPlatform.type === 'google') {
      return <SignInGoogle {...socialPlatform} />
    }
    return null
  }, [])

  if (loading) {
    return null
  }

  return (
    <>
      {data?.amSocialLoginButtonConfig?.map(social => social ? getSocialComponent(social) : null)}
    </>
  )
}
