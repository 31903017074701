import { useContext } from 'react'
import { Icon } from '../Layout/Icon'
import { globalContext } from '../NextUi/globalContext'

export function Promo({ text }: { text: string }) {
  const { promoOpen, closePromo } = useContext(globalContext)

  if (!promoOpen) {
    return null
  }

  return (
    <div className='h-[55px]  bg-concept-print-orange text-center'>
      <div className='mx-auto flex h-[100%] max-w-maxContentWidth items-center justify-between px-[10px] 2xl:px-0'>
        <div />
        <p className='Type-XL-Bold text-concept-print-white'>{text}</p>
        <Icon
          name='close'
          className='Type-XXL-Bold cursor-pointer pr-[10px] text-concept-print-white'
          onClick={closePromo}
        />
      </div>
    </div>
  )
}
