/* eslint-disable */
import * as Types from '@graphcommerce/graphql-mesh/.mesh';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

export const SocialSignInsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"SocialSignIns"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"amSocialLoginButtonConfig"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"label"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"url"}}]}}]}}]} as unknown as DocumentNode<SocialSignInsQuery, SocialSignInsQueryVariables>;
export type SocialSignInsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SocialSignInsQuery = { amSocialLoginButtonConfig?: Array<{ label?: string | null, type?: string | null, url?: string | null } | null> | null };
