import { useEffect, useState } from 'react'

export type DetectDeviceProps = {
  isAndroid?: boolean
  isIOS?: boolean
  isIPad?: boolean
  isMobile?: boolean
  isTablet?: boolean
  isWindows?: boolean
}
const useDetectDevice = () => {
  const [deviceDetails, setDeviceDetails] = useState<DetectDeviceProps>()

  const detectDevice = {
    isAndroid() {
      return !!window?.navigator?.userAgent?.match(/Android/i)
    },
    isIOS() {
      return !!window?.navigator?.userAgent?.match(/iPhone|iPad|iPod/i)
    },
    isIPad() {
      return !!window?.navigator?.userAgent?.match(/iPad/i)
    },
    isMobile() {
      return !!window?.navigator?.userAgent?.match(/Mobile/i)
    },
    isTablet() {
      return !!window?.navigator?.userAgent?.match(/Tablet/i)
    },
    isWindows() {
      return (
        !!window?.navigator?.userAgent?.match(/IEMobile/i) ||
        !!window?.navigator?.userAgent?.match(/WPDesktop/i)
      )
    },
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDeviceDetails({
        isAndroid: detectDevice.isAndroid(),
        isIOS: detectDevice.isIOS(),
        isIPad: detectDevice.isIPad(),
        isMobile: detectDevice.isMobile(),
        isTablet: detectDevice.isTablet(),
        isWindows: detectDevice.isWindows(),
        // isIOS() {
        //   return window?.navigator?.userAgent?.match(/iPhone|iPad|iPod/i)
        // },
        // isIPad() {
        //   return window?.navigator?.userAgent?.match(/iPad/i)
        // },
        // isMobile() {
        //   return window?.navigator?.userAgent?.match(/Mobile/i)
        // },
        // isTablet() {
        //   return window?.navigator?.userAgent?.match(/Tablet/i)
        // },
        // isWindows() {
        //   return (
        //     window?.navigator?.userAgent?.match(/IEMobile/i) ||
        //     window?.navigator?.userAgent?.match(/WPDesktop/i)
        //   )
        // }
      })
    }

    // only execute all the code below in client side
  }, [])
  return deviceDetails
}

export default useDetectDevice
