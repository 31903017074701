import Link from 'next/link'
import { Dispatch, SetStateAction } from 'react'
import missingFile from '../../assets/images/missing-file.png'
import { ResponsiveImageGridItem } from '../Image/ResponsiveImageGridItem'

type SubCategoryTabProps = {
  menuItems?: any
  className?: string
  url?: string
  setShowMenu: Dispatch<SetStateAction<boolean>>
}

export function SubCategoryTab(props: SubCategoryTabProps) {
  const { menuItems, className, url, setShowMenu } = props
  const displayMenuItems = menuItems.filter((item) => item?.include_in_menu)
  const topCategories = displayMenuItems?.filter((item, index) => index <= 3)
  const remainingCategories = displayMenuItems?.filter((item, index) => index > 3)

  return (
    <>
      <div
        className={`grid w-full grid-cols-4 items-start justify-center gap-x-5 gap-y-10  border-b-2 border-light-gray ${className}`}
      >
        {topCategories
          ?.filter((item) => item?.include_in_menu)
          .map((category) => (
            <Link
              href={`/${category.url_path}` ?? ''}
              onClick={() => setShowMenu(false)}
              key={category.categoryLabel}
              className='w-full  text-black hover:text-material-ui-blue'
            >
              {category.image ? (
                <ResponsiveImageGridItem
                  src={category?.thumbnail ?? category?.image}
                  alt={category?.thumbnail_alt ?? category?.image_alt}
                  aspect='4/3'
                />
              ) : (
                <ResponsiveImageGridItem
                  src={missingFile?.src}
                  alt={category.image_alt ?? ''}
                  aspect='4/3'
                />
              )}
              <span className='md:Type-XL-Regular Type-Large-Regular mt-2 text-center md:my-3'>
                {category.name}
              </span>
            </Link>
          ))}
      </div>
      <div
        className={`mt-10 grid w-full grid-cols-4 items-start justify-center gap-x-5 gap-y-10 pb-5 ${className}`}
      >
        {remainingCategories
          ?.filter((item) => item?.include_in_menu)
          .map((category) => (
            <Link
              href={`/${category.url_path}` ?? ''}
              onClick={() => setShowMenu(false)}
              key={category.categoryLabel}
              className='w-full  text-black hover:text-material-ui-blue'
            >
              {category.image ? (
                <ResponsiveImageGridItem
                  src={category?.thumbnail ?? category?.image}
                  alt={category?.thumbnail_alt ?? category?.image_alt}
                  aspect='4/3'
                />
              ) : (
                <ResponsiveImageGridItem
                  src={missingFile?.src}
                  alt={category.image_alt ?? ''}
                  aspect='4/3'
                />
              )}
              <span className='md:Type-XL-Regular Type-Large-Regular mt-2 text-center md:my-3'>
                {category.name}
              </span>
            </Link>
          ))}
      </div>
    </>
  )
}
