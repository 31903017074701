import { Image } from '@graphcommerce/image'
import { Accordion, AccordionDetails, AccordionSummary, Tab, styled } from '@mui/material'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { ReactElement, useState } from 'react'
import expandIcon from '../../assets/images/arrow-down.svg'
import missingFile from '../../assets/images/missing-file.png'
import missingImageIcon from '../../assets/images/missing-image-icon.svg'

import { ResponsiveImageGridItem } from '../Image/ResponsiveImageGridItem'

export const MenuAccordion = styled(Accordion)({
  border: 0,
  background: 'none',

  '&:before': {
    display: 'none',
  },
})

type SubHeaderTabsMobileProps = {
  menuItems?: any
  className?: string
  onClose: () => void
}

export interface VerticalTabProps {
  label: string
  id: string
  value: string
  'aria-controls': string
  onHover?: () => void
  icon?: ReactElement<any>
  onClick?: () => void
}

export const VerticalTab = styled((props: VerticalTabProps) => (
  <Tab
    disableRipple
    {...props}
    onMouseEnter={props.onHover}
    icon={props.icon}
    onClick={() => props.onClick && props?.onClick()}
  />
))(() => ({
  whiteSpace: 'nowrap',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyItems: 'start',
  justifyContent: 'start',
  gap: 20,
  marginBottom: 0,
  minHeight: 56,
  color: 'rgba(0, 0, 0, 0.6)',
  'img.MuiTab-iconWrapper': {
    marginBottom: 0,
    height: 30,
    width: 30,
  },
  ':hover': {
    backgroundColor: 'rgba(97,51,148,0.1)',
  },
}))

/**
 * This is a test doc
 *
 * @param SubHeaderTabsMobileProps @
 */
export function SubHeaderTabsMobile(props: SubHeaderTabsMobileProps) {
  const { menuItems, className, onClose } = props
  const a11yProps = (index: number) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  })
  const router = useRouter()

  const handleNavigate = async (href: string) => {
    await router.push(href)
    onClose()
  }
  const [openedIndex, setOpenedIndex] = useState<number>(-1)

  return (
    <div className={`ml-2 flex w-full flex-col ${className}`}>
      {menuItems
        .filter((item) => item?.include_in_menu)
        ?.map((item, index) => (
          <MenuAccordion
            elevation={0}
            disableGutters
            expanded={index === openedIndex}
            onClick={() => {
              if (index === openedIndex) {
                setOpenedIndex(-1)
              } else {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                setOpenedIndex(parseInt(index, 10))
              }
            }}
          >
            <AccordionSummary
              expandIcon={
                item?.children?.filter((i) => i?.include_in_menu)?.length > 0 ? (
                  <Image
                    src={expandIcon}
                    alt='expand or close'
                    layout='fixed'
                    unoptimized
                    height={40}
                    width={40}
                  />
                ) : null
              }
              sx={{
                padding: 0,
                margin: 0,
              }}
            >
              <Link
                href={`/${item.url_path}` ?? ''}
                className='flex  w-full flex-row items-center justify-start gap-2 text-black hover:text-material-ui-blue'
                onClick={() => onClose()}
              >
                {item?.thumbnail ?? item?.image ? (
                  <Image
                    unoptimized
                    layout='fixed'
                    src={item?.thumbnail ?? item?.image ?? ''}
                    alt={item?.thumbnail_alt ?? item?.image_alt ?? ''}
                    height={30}
                    width={30}
                    className='flex h-[30px] w-[30px] items-center justify-center rounded-[50%]'
                  />
                ) : (
                  <Image
                    unoptimized
                    layout='fixed'
                    src={missingImageIcon}
                    height={30}
                    width={30}
                    className='flex h-[30px] w-[30px] items-center justify-center rounded-[50%]'
                  />
                )}
                <h3 className='Type-XL-Regular mt-2 uppercase hover:text-material-ui-blue'>
                  {item.name}
                </h3>
              </Link>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0, margin: 0, border: 0 }}>
              <div className='my-2 grid grid-cols-2 items-start justify-items-start gap-2'>
                {item?.children
                  .filter((i) => i?.include_in_menu)
                  .map((child) => (
                    <div>
                      <Link
                        href={`/${child.url_path}` ?? ''}
                        className='block  w-full items-start gap-1 text-black hover:text-material-ui-blue '
                        onClick={() => onClose()}
                      >
                        <div className='max-h-[120px] max-w-[150px]'>
                          {child?.image ? (
                            <ResponsiveImageGridItem
                              src={child.image ?? ''}
                              alt={child.image_alt ?? ''}
                              aspect='5/3'
                            />
                          ) : (
                            <ResponsiveImageGridItem
                              src={missingFile?.src ?? ''}
                              alt={child.image_alt ?? ''}
                              aspect='5/3'
                            />
                          )}
                        </div>
                        <span className='Type-Large-Regular mt-2 text-start'>
                          {child.name ?? ''}
                        </span>
                      </Link>
                    </div>
                  ))}
              </div>
            </AccordionDetails>
          </MenuAccordion>
        ))}
    </div>
  )
}
