import { useApolloClient } from '@graphcommerce/graphql'
import { ApolloCustomerErrorAlert } from '@graphcommerce/magento-customer/components/ApolloCustomerError/ApolloCustomerErrorAlert'
import { CustomerTokenDocument } from '@graphcommerce/magento-customer/hooks'
import { graphqlErrorByCategory } from '@graphcommerce/magento-graphql'
import { FormActions } from '@graphcommerce/next-ui'
import { useFormGqlMutation, emailPattern } from '@graphcommerce/react-hook-form'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { Box, FormControl, SxProps, Theme } from '@mui/material'
import { useRouter } from 'next/router'
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { Button } from '../../Button'
import { Icon } from '../../Layout/Icon'
import { Message } from '../../Message'
import { globalContext } from '../../NextUi/globalContext'
import { TextInput } from '../../TextInput'
import { SignInSubUserDocument } from './SignInSubUser.gql'

type SignInFormProps = {
  email: string
  sx?: SxProps<Theme>
  switchModeToForgotPass: () => void
  disableFields?: boolean
  register?: any
  mode?: any
  hasSocials?: boolean
  setHaSocials?: Dispatch<SetStateAction<boolean>>
}

export function SubUserSignInForm(props: SignInFormProps) {
  const {
    email,
    sx,
    disableFields,
    register: registerEmail,
    mode,
    hasSocials,
    setHaSocials,
    switchModeToForgotPass,
  } = props

  const { drawer, setRedirectAfterSignIn } = useContext(globalContext)
  const router = useRouter()

  const client = useApolloClient()
  const form = useFormGqlMutation(
    SignInSubUserDocument,
    {
      defaultValues: { email },
      onComplete: ({ data }) => {
        const token = data?.bssGenerateSubUserToken?.token
        if (token) {
          client.cache.writeQuery({
            query: CustomerTokenDocument,
            data: {
              customerToken: {
                token,
                __typename: 'CustomerToken',
                createdAt: new Date().toUTCString(),
                valid: true,
              },
            },
          })

          router
            .push('/')
            .then(() => {})
            .catch(() => {})
        }
      },
    },
    { errorPolicy: 'all' },
  )

  const { register, handleSubmit, required, formState, error } = form
  const [remainingError, authError] = graphqlErrorByCategory({
    category: 'graphql-authentication',
    error,
  })
  const submitHandler = handleSubmit(() => {
    if (drawer.id === 'auth' && typeof drawer.params?.redirectAfterSignIn === 'string') {
      setRedirectAfterSignIn(drawer.params?.redirectAfterSignIn)
    }
  })

  const [authErrorMessage, setAuthErrorMessage] = useState<string>('')

  useEffect(() => {
    const authErrorMessageLocal =
      authError && authError?.message?.indexOf('The account sign-in was incorrect') >= 0
        ? 'Invalid password, please try again'
        : authError?.message

    if ((authError?.extensions?.hasSocials as Array<string>)?.includes('google') && setHaSocials) {
      setHaSocials(true)
      setAuthErrorMessage(
        i18n._(
          'This account has Google sign-in enabled. Please click the button above to sign in with Google instead.',
        ),
      )
    } else {
      setAuthErrorMessage(authErrorMessageLocal ?? '')
    }
  }, [authError, setHaSocials])

  return (
    <Box component='form' onSubmit={submitHandler} noValidate sx={sx}>
      {!hasSocials && (
        <div className='flex flex-col justify-center gap-[22px]'>
          <TextInput
            topLabel={<Trans id='Email address' />}
            required={required.email}
            error={formState.isSubmitted && !!formState.errors.email}
            disabled={disableFields || formState.isSubmitting}
            {...registerEmail('email', {
              required: required.email,
              pattern: { value: emailPattern, message: '' },
            })}
            type='email'
            className='w-full'
            containerClassName='w-[260px] min-w-[260px] max-w-full'
            autoComplete='email'
          />
          <TextInput
            key='password'
            type='password'
            error={!!formState.errors.password || !!authError}
            topLabel={<Trans id='Password' />}
            autoFocus
            autoComplete='current-password'
            id='current-password'
            required={required.password}
            containerClassName='w-[260px] min-w-[260px] max-w-full'
            helperText={formState.errors.password?.message}
            disabled={formState.isSubmitting}
            {...register('password', { required: required.password })}
            className='w-full'
          >
            <div className='mt-[5px] hidden text-right'>
              <button
                className='Type-Large-Medium text-turquoise'
                onClick={() => switchModeToForgotPass()}
                type='button'
              >
                <Trans id='Forgot password?' />
              </button>
            </div>
          </TextInput>
        </div>
      )}

      {authError && <Message containerClassName='mt-[20px]' content={authErrorMessage} />}

      <ApolloCustomerErrorAlert error={remainingError} key='error' />

      {!hasSocials && mode !== 'signup' && (
        <FormActions>
          <FormControl>
            <Button
              type='submit'
              loading={formState.isSubmitting}
              color='blue'
              label={
                <span className='flex items-center'>
                  <Trans id='Continue' />{' '}
                  <Icon name='arrow_forward' className='ml-[10px] text-[20px] font-bold' />
                </span>
              }
              className='px-5 py-1'
            />
          </FormControl>
        </FormActions>
      )}
    </Box>
  )
}
