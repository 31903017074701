import { useCustomerQuery, useCustomerSession } from '@graphcommerce/magento-customer'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { CompanyAccountDocument } from '../Account/SubUser/CompanyAccount.gql'
import { DrawerParams, globalContext } from './globalContext'

export type GlobalProviderProps = {
  children: React.ReactNode
}

export const RolePermissions = [
  { id: 1, code: 'dashboard' },
  { id: 4, code: 'address-book' },
  { id: 5, code: 'stored-payment-method' },
  { id: 6, code: 'manage-sub-users-and-roles' },
  { id: 7, code: 'view-all-orders' },
  { id: 8, code: 'view-reports' },
  { id: 9, code: 'create-cart' },
  { id: 10, code: 'view-all-carts' },
  { id: 11, code: 'place-order' },
  { id: 12, code: 'place-order-waiting' },
  { id: 13, code: 'approve-order-waiting' },
]

export function GlobalProvider(props: GlobalProviderProps) {
  const { children } = props
  const [promoOpen, setPromoOpen] = useState(false)
  const [isCcFormValid, setIsCcFormValid] = useState(false)
  const [isUsingCcPayment, setIsUsingCcPayment] = useState(false)
  const [cartMergeInProgress, setCartMergeInProgress] = useState(false)
  const [redirectAfterSignIn, setRedirectAfterSignIn] = useState<string | null>()
  const [eventCalledFor, setEventCalledFor] = useState<Record<'beginCheckout', string[]>>({
    beginCheckout: [],
  })
  const { loggedIn } = useCustomerSession()
  const router = useRouter()
  const [drawer, setDrawer] = useState<{
    id: null | false | 'nav' | 'auth' | 'cart' | 'account' | 'added_to_cart'
    params?: DrawerParams
  }>({ id: null, params: {} })

  const companyAccount = useCustomerQuery(CompanyAccountDocument, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  })

  useEffect(() => {
    const { showAuthModal } = router.query
    if (showAuthModal?.toString() === '1') {
      setDrawer({ id: 'auth' })
    }
    if (typeof router.query.redirectAfterSignIn === 'string') {
      setRedirectAfterSignIn(router.query.redirectAfterSignIn)
    }
  }, [router.query])

  const subUserCan = useCallback(
    (permissionCode: string) => {
      if (
        companyAccount.data?.customer?.bss_is_company_account &&
        !companyAccount.data.bssSubUser
      ) {
        // Company Account
        return true
      }
      if (companyAccount.data?.bssSubUser) {
        // Sub User

        // Allow everything if admin
        if (
          companyAccount.data?.bssSubUser?.role?.role_type === '0' &&
          companyAccount.data?.bssSubUser?.role?.role_name === 'admin'
        ) {
          return true
        }

        const permissionId = RolePermissions.find((perm) => perm.code === permissionCode)?.id
        if (!permissionId) {
          return false
        }
        if (permissionId) {
          return companyAccount.data?.bssSubUser?.role?.role_type
            ?.split(',')
            .includes(permissionId.toString())
        }
      }
      // Regular Customer
      return permissionCode !== 'manage-sub-users-and-roles'
    },
    [companyAccount.data],
  )

  useEffect(() => {
    if (typeof localStorage !== 'undefined') {
      if (typeof localStorage.getItem('promoOpen') === 'string') {
        const promoOpenJson = localStorage.getItem('promoOpen')
        if (
          promoOpenJson &&
          JSON.parse(promoOpenJson) &&
          typeof JSON.parse(promoOpenJson) === 'boolean'
        )
          setPromoOpen(JSON.parse(promoOpenJson) as boolean)
      } else {
        setPromoOpen(true)
      }
      if (typeof localStorage.getItem('eventCalledFor') === 'string') {
        const eventCalledForJson = localStorage.getItem('eventCalledFor')
        if (
          eventCalledForJson &&
          JSON.parse(eventCalledForJson) &&
          typeof JSON.parse(eventCalledForJson) === 'object'
        )
          setEventCalledFor(JSON.parse(eventCalledForJson))
      }
    }
  }, [])

  const closePromo = useCallback(() => {
    setPromoOpen(false)
    if (localStorage) {
      localStorage.setItem('promoOpen', JSON.stringify(false))
    }
  }, [setPromoOpen])

  const addEventCalledFor = useCallback(
    (event: 'beginCheckout', id: string) => {
      const updatedValue = { ...eventCalledFor, [event]: [...eventCalledFor[event], id] }
      setEventCalledFor(updatedValue)
      if (localStorage) {
        localStorage.setItem('eventCalledFor', JSON.stringify(updatedValue))
      }
    },
    [eventCalledFor],
  )

  // We do it here and not after sign in form submit, coz we
  // need to wait until signIn = true in order to avoid auth errors on checkout page (even for few miliseconds)
  useEffect(() => {
    if (!redirectAfterSignIn || !loggedIn) {
      return
    }
    router
      .push(redirectAfterSignIn)
      .then(() => {})
      .catch(() => {})
    setRedirectAfterSignIn(null)
  }, [redirectAfterSignIn, router, loggedIn])

  return (
    <globalContext.Provider
      value={useMemo(
        () => ({
          promoOpen,
          closePromo,
          drawer,
          setDrawer,
          cartMergeInProgress,
          setCartMergeInProgress,
          isCcFormValid,
          setIsCcFormValid,
          isUsingCcPayment,
          setIsUsingCcPayment,
          eventCalledFor,
          addEventCalledFor,
          isCompanyAccount: companyAccount.data?.customer?.bss_is_company_account || false,
          /** Temporarily disable Company Accounts */
          // companyCategories: companyAccount.data?.customer?.company_categories,
          isSubUser: !!companyAccount.data?.bssSubUser,
          subUserCan,
          setRedirectAfterSignIn,
        }),
        [
          promoOpen,
          closePromo,
          drawer,
          setDrawer,
          cartMergeInProgress,
          setCartMergeInProgress,
          isCcFormValid,
          setIsCcFormValid,
          isUsingCcPayment,
          setIsUsingCcPayment,
          eventCalledFor,
          addEventCalledFor,
          companyAccount.data?.customer?.bss_is_company_account,
          // companyAccount.data?.customer?.company_categories,
          companyAccount.data?.bssSubUser,
          subUserCan,
          setRedirectAfterSignIn,
        ],
      )}
    >
      {children}
    </globalContext.Provider>
  )
}
