import { TabContext, TabPanel } from '@mui/lab'
import { Box, Tab, Tabs, styled } from '@mui/material'
import { useEffect, useState } from 'react'
// eslint-disable-next-line import/no-cycle
import { LayoutQuery } from '../Layout'
import { SubHeaderTabsMobile } from './SubHeaderTabsMobile'

export interface StyledTabsProps {
  children?: React.ReactNode
  value: string
  onChange: (event: React.SyntheticEvent, newValue: string) => void
  className?: string
  variant?: 'standard' | 'scrollable' | 'fullWidth' | undefined
  scrollButtons?: boolean
  allowScrollButtonsMobile?: boolean
}

export const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span className='MuiTabs-indicatorSpan' /> }} />
))(({ theme }) => ({
  color: '#6A6A6A',
  minHeight: 36,
  height: 36,
  marginRight: 0,
  [(theme as any).breakpoints.up('md')]: {
    marginRight: '40px',
    marginLeft: 0,
  },
  backgroundColor: 'white',
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: 4,
  },
  '& .MuiTabs-indicatorSpan': {
    backgroundColor: 'none',
  },
}))

export interface StyledTabProps {
  label: string
  id: string
  value: string
  'aria-controls': string
  onHover?: () => void
}

export const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} onMouseEnter={props.onHover} />
))(({ theme }) => ({
  minWidth: 0,
  minHeight: 36,
  height: 36,
  paddingRight: 20,
  paddingLeft: 20,
  marginRight: '20px',
  textAlign: 'center',
  textTransform: 'uppercase',
  color: '#6A6A6A',
  fontSize: 16,
  fontWeight: 500,

  ':hover': {
    color: '#000000',
  },
  '&.Mui-selected': {
    color: '#2196F3',
    borderBottom: '2px solid #2196F3',
    fontWeight: '500',
    ':hover': {
      color: '#0069D9',
      borderColor: '#0069D9',
    },
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#983FF1',
  },
}))

export interface StyledTabPanelProps {
  value: string
  children?: React.ReactNode
}

export const StyledTabPanel = styled((props: StyledTabPanelProps) => <TabPanel {...props} />)(
  ({ theme }) => ({
    width: '100%',
    zIndex: 10,
    left: 0,
    top: 56,
    padding: 0,
    maxWidth: '1054px',
    '&.MuiTabPanel-root': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  }),
)

export function HeaderTabsMobile(props: Pick<LayoutQuery, 'menu'> & { onClose: () => void }) {
  const { menu, onClose } = props
  const firstLevelParentNodes = menu?.items
  const firstLevelNodes =
    firstLevelParentNodes && firstLevelParentNodes[0] && firstLevelParentNodes[0]?.children
      ? firstLevelParentNodes[0]?.children.filter((node) => node?.include_in_menu)
      : []

  const [value, setValue] = useState('0')
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  const a11yProps = (index: number) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  })

  return (
    <TabContext value={value}>
      <Box className='flex w-full items-center justify-start bg-none text-black'>
        <div className='flex w-full items-start justify-start'>
          <StyledTabs
            value={value}
            onChange={handleChange}
            variant='scrollable'
            scrollButtons
            allowScrollButtonsMobile
          >
            {firstLevelNodes &&
              firstLevelNodes?.length > 0 &&
              firstLevelNodes.map((item, index) => (
                <StyledTab
                  label={item?.name ?? ''}
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                  {...a11yProps(index)}
                  value={index.toString()}
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                />
              ))}
          </StyledTabs>
        </div>
      </Box>
      {firstLevelNodes &&
        firstLevelNodes.map((item, index) => (
          <div className=''>
            <StyledTabPanel value={index.toString()}>
              <SubHeaderTabsMobile
                menuItems={item?.children?.filter((node) => node?.include_in_menu)}
                onClose={onClose}
              />
            </StyledTabPanel>
          </div>
        ))}
    </TabContext>
  )
}
