import { i18n } from '@lingui/core'
import { SwipeableDrawer } from '@mui/material'
import Link from 'next/link'
import { useContext } from 'react'
import useDetectDevice, { DetectDeviceProps } from '../../hooks/useDetectDevice'
import useWindowSize from '../../hooks/useWindowSize'
import { CustomerFab } from '../Customer/CustomerFab/CustomerFab'
import { OrdersFab } from '../Customer/OrdersFab/OrdersFab'
// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
import { HeaderTabs } from '../Header/HeaderTabs'
import { NavigationDesktop } from '../Header/NavigationDesktop'
import { NavigationMobile } from '../Header/NavigationMobile'
import { Promo } from '../Header/Promo'
import { CartFab } from '../Minicart/CartFab'
import { globalContext } from '../NextUi/globalContext'
import { SearchForm } from '../Search/SearchForm'
import { HeaderQueryFragment } from './HeaderQueryFragment.gql'
import { Icon } from './Icon'
import { LayoutQuery } from './Layout.gql'
import { Logo } from './Logo'

export type HeaderProps = Pick<LayoutQuery, 'menu'> & HeaderQueryFragment

export function Header(props: HeaderProps) {
  const { menu, header } = props
  const { isMobile } = useWindowSize()
  const { drawer, setDrawer } = useContext(globalContext)
  const deviceDetails: DetectDeviceProps = useDetectDevice() || {}
  const isTablet = deviceDetails?.isTablet || deviceDetails?.isIPad

  return (
    <header className='sticky top-0 z-50 block w-full border-b-[1px] border-b-20-grey bg-concept-print-orange '>
      {!isMobile && header?.promoText ? (
        <Promo text={header?.promoText} />
      ) : (
        isMobile && (
          <div className='flex h-[55px] items-center justify-center bg-templi-purple'>
            <Link href='/'>
              <Logo mode='white' />
            </Link>
          </div>
        )
      )}
      <div className='flex w-full items-center justify-center'>
        <div
          className={`mx-auto flex h-[60px] w-full max-w-maxContentWidth grid-cols-[45fr_55fr] items-center justify-between px-[8px] sm:h-auto sm:border-none ${
            isTablet ? 'md:ml-[36px] lg:ml-[36px]' : 'md:mx-[36px] lg:mx-[36px]'
          } md:grid md:justify-between 2xl:px-0`}
        >
          {!isMobile && (
            <div className='flex items-center justify-start'>
              <Link href='/'>
                <div className='h-full'>
                  <Logo />
                </div>
              </Link>
              {!isTablet && <HeaderTabs menu={menu} />}
            </div>
          )}
          <div className='flex w-full items-center justify-between py-2 md:py-0'>
            <SearchForm aria-label={i18n._(/* i18n */ 'Search...')} />
            <div className='ml-[30px] flex items-center justify-end gap-[20px] md:gap-[30px]'>
              {!isMobile && <OrdersFab />}
              <CustomerFab />
              <CartFab />
              {isTablet && (
                <button
                  type='button'
                  className='ml-[24px] inline-flex h-full items-center border-l-[0.5px] border-l-20-grey py-4 pl-[10px]'
                  onClick={() => setDrawer({ id: drawer.id === 'nav' ? null : 'nav' })}
                >
                  <Icon name='menu' className='cursor-pointer  text-2xl text-pure-white' />
                </button>
              )}
            </div>
          </div>
          {isMobile && !isTablet && (
            <button
              type='button'
              className='ml-[24px] inline-flex h-full items-center border-l-[0.5px] border-l-20-grey pl-[10px]'
              onClick={() => setDrawer({ id: drawer.id === 'nav' ? null : 'nav' })}
            >
              <Icon name='menu' className='cursor-pointer  text-2xl text-pure-white' />
            </button>
          )}
        </div>
      </div>
      {(isMobile || isTablet) && (
        <SwipeableDrawer
          anchor='right'
          onClose={() => setDrawer({ id: null })}
          onOpen={() => {}}
          open={drawer.id === 'nav'}
          classes={{
            paper: 'rounded-l-[10px] min-h-[69px] max-w-[450px] w-[80%]',
          }}
        >
          <NavigationMobile menu={menu} closeModal={() => setDrawer({ id: null })} />
        </SwipeableDrawer>
      )}
    </header>
  )
}
