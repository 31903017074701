import { ReactNode } from 'react'
import { Icon } from '../Layout/Icon'

type MessageProps = {
  content: string | ReactNode
  type?: 'error' | 'success'
  containerClassName?: string
}

const colorMap = {
  error: {
    boxBg: 'bg-[#FFEE95]',
    boxBd: 'border-b-[#FFD630]',
    iconBg: 'bg-marigold',
  },
}

export function Message(props: MessageProps) {
  const { content, type = 'error', containerClassName = '' } = props
  return (
    <div
      className={`flex items-center gap-[10px] border-b-[2px] ${colorMap[type].boxBd}  ${colorMap[type].boxBg} px-[15px] py-[10px] ${containerClassName}`}
    >
      <div className='relative'>
        <div
          className={`flex h-[30px] w-[30px] items-center justify-center rounded-full ${colorMap[type].iconBg}`}
        >
          <Icon name='priority_high' className='text-[16px]' />
        </div>
      </div>
      <p className='Type-Base-Regular'>{content}</p>
    </div>
  )
}
