import { useCustomerSession } from '@graphcommerce/magento-customer'
import { i18n } from '@lingui/core'
import { useRouter } from 'next/router'
import { useState, useContext } from 'react'
import { WhiteButton } from '../Button/ButtonStyles'
import { Icon } from '../Layout/Icon'
import { LayoutQuery } from '../Layout/Layout.gql'
import { globalContext } from '../NextUi/globalContext'
// eslint-disable-next-line import/no-cycle
import { HeaderTabsMobile } from './HeaderTabsMobile'
import { NavigationFooterLinks } from './NavigationFooterLinks'

export const NavigationMobile = (props: Pick<LayoutQuery, 'menu'> & { closeModal: () => void }) => {
  const { menu, closeModal } = props

  const { drawer, setDrawer } = useContext(globalContext)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [isNavigating, setIsNavigating] = useState<boolean>(false)

  const router = useRouter()
  const session = useCustomerSession()

  const handleNavigateToAccountSettings = async () => {
    setIsNavigating(true)
    await router.push('/account?navigateTo=2')
    closeModal()
    setIsNavigating(false)
  }

  return (
    <div className='flex h-screen flex-col justify-between'>
      <div className='p-4'>
        <HeaderTabsMobile menu={menu} onClose={closeModal} />
        <button
          type='button'
          className='absolute right-[10px] top-[10px] mt-2 flex h-[32px] w-[32px] cursor-pointer items-center justify-center rounded-full'
          onClick={closeModal}
        >
          <Icon name='close' className='Type-XXXL-Bold text-tight-black' />
        </button>
        <div className='mt-9 flex w-full items-center justify-center'>
          {!session?.loggedIn && (
            <WhiteButton
              type='button'
              className='w-auto bg-[#FFFFFF] px-8 uppercase'
              variant='outlined'
              size='small'
              onClick={() => setDrawer({ id: drawer.id === 'auth' ? null : 'auth' })}
              sx={{
                borderRadius: '25px',
              }}
            >
              {i18n._('Sign up / Log in')}
            </WhiteButton>
          )}
          {session?.loggedIn && (
            <WhiteButton
              type='button'
              className='w-auto rounded-[25px] bg-[#FFFFFF] px-8 uppercase'
              variant='outlined'
              size='small'
              onClick={async () => handleNavigateToAccountSettings()}
              loading={isNavigating}
            >
              {i18n._('My Account')}
            </WhiteButton>
          )}
        </div>
      </div>
      <div>
        <NavigationFooterLinks />
      </div>
    </div>
  )
}
