import { Image } from '@graphcommerce/image'
import { NoSsr } from '@mui/material'
import Link from 'next/link'
import amex from '../../assets/images/amex.svg'
import svgLogoWhite from '../../assets/images/concept-logo-white.svg'
import logoWhite from '../../assets/images/concept-name-white.svg'
import discover from '../../assets/images/discover.svg'
import facebook from '../../assets/images/facebook.svg'
import background from '../../assets/images/footer-background.png'
import instagram from '../../assets/images/instagram.svg'
import linkedin from '../../assets/images/linkedin.svg'

import mastercard from '../../assets/images/mastercard.svg'
import tel from '../../assets/images/telephone.svg'
import visa from '../../assets/images/visa.svg'
import { FooterQueryFragment } from '../Layout/FooterQueryFragment.gql'

type Social = {
  // Use any to avoid conflicts with @svgr/webpack plugin or babel-plugin-inline-react-svg plugin.
  // eslint-disable-next-line
  image: any
  href: string
  alt: string
  isMobile: boolean
  id: string
}

export function FooterResponsive(props: FooterQueryFragment) {
  const { footer } = props
  const socials: Social[] = [
    {
      image: instagram,
      href: 'https://www.instagram.com/conceptprint',
      alt: 'instagram',
      isMobile: true,
      id: 'socialsFooterIg',
    },
    {
      image: facebook,
      href: 'https://www.facebook.com/ConceptPrint1993',
      alt: 'facebook',
      isMobile: true,
      id: 'socialsFooterFB',
    },
    {
      image: linkedin,
      href: 'https://www.linkedin.com/company/conceptprintinginc',
      alt: 'linkedin',
      isMobile: true,
      id: 'socialsFooterLI',
    },
  ]

  return (
    <footer className='bg-concept-print-orange px-[35px] pb-[6px] pt-[24px] text-pure-white md:flex lg:p-0'>
      <div className='mb-[18px] flex items-center justify-between lg:relative lg:mb-0 lg:block'>
        <div className='footerImage hidden h-full min-h-full md:block'>
          <Link href='/' legacyBehavior>
            <div className='flex h-[54px] flex-col items-center justify-center px-8 md:pt-[80px] lg:pt-[100px]'>
              <Image
                alt='Concept Print Logo'
                src={svgLogoWhite}
                className='scale-[70%]'
                unoptimized
              />
              <Image
                src={logoWhite}
                alt='Concept Print Logo'
                unoptimized
                layout='fixed'
                className='scale-[80%]'
              />
            </div>
          </Link>
        </div>
        <Link
          href='/'
          className='ml-[20px] flex h-[34px] w-[106px] scale-150 items-center justify-center md:hidden'
        >
          <Image src={logoWhite} alt='Concept Print Logo' unoptimized className='h-full w-full' />
        </Link>
        {/* <div className='flex gap-x-7 lg:hidden'>
          {socials.map(
            (social) =>
              social.isMobile && (
                <a href={social.href} key={social.href}>
                  <Image src={social.image} alt={social.alt} unoptimized />
                </a>
              ),
          )}
        </div> */}
      </div>
      <div className='grow-[0.5] text-concept-print-white lg:pb-[27px]  lg:pt-[48px]'>
        <div className='max-[400px] mb-[17px] flex justify-around lg:mb-[45px] lg:items-start lg:gap-x-10'>
          <div>
            <p className='Type-XXL-Bold lg:Type-XXXL-Bold mb-3.5 lg:mb-4'>Resources</p>
            <ul>
              {footer?.resources?.map((resource) => (
                <li key={resource.label} className='mb-[8px]'>
                  <span className='Type-Large-Medium lg:Type-XL-Medium hover:text-pure-white-hover hover:underline'>
                    <Link href={resource.url}>{resource.label}</Link>
                  </span>
                </li>
              ))}
            </ul>
          </div>
          {/* <div>
            <p className='Type-XXL-Bold lg:Type-XXXL-Bold mb-3.5 text-concept-print-white lg:mb-4'>
              Top Products
            </p>
            <ul>
              {footer?.topProducts?.map((product) => (
                <li key={product.label} className='mb-[8px]'>
                  <span className='Type-Large-Medium lg:Type-XL-Medium hover:text-pure-white-hover hover:underline'>
                    <Link href={product.url}>{product.label}</Link>
                  </span>
                </li>
              ))}
            </ul>
          </div> */}
        </div>
      </div>
      <div className='flex grow-[0.5] flex-col items-center justify-between text-concept-print-white lg:pb-[27px] lg:pt-[48px]'>
        <div className='flex h-full flex-col items-center justify-between gap-[35px]'>
          <div className='epadding-[5px] hidden w-full flex-col items-center justify-center rounded-[5px] border-[1px] border-concept-print-white md:block'>
            <span className='Type-XXXL-Bold flex w-full max-w-[300px] items-center justify-center border-b-[1px] bg-concept-print-white py-[5px] text-concept-print-orange'>
              Contact
            </span>
            <div className='flex w-[300px] max-w-[300px] items-center justify-center p-[10px] text-concept-print-white'>
              <div>
                <span className='mb-[10px] grid grid-cols-[20fr_80fr] items-center justify-between'>
                  <span className='material-symbols-outlined mr-[5px]'>location_on</span>
                  <span className='Type-XL-Medium ml-[2px]'>Concept Print</span>
                  <div />
                  <span className='Type-XL-Medium ml-[2px]'> 40 Lydecker Street</span>
                  <div />
                  <span className='Type-XL-Medium ml-[2px]'> Nyack, NY 10960</span>
                </span>
                <span className='mb-[10px] grid grid-cols-[20fr_80fr] items-center justify-between'>
                  <span className='material-symbols-outlined mr-[5px]'>mail</span>
                  <span className='Type-XL-Medium ml-[2px]'>
                    <a href='mailto:info@conceptprint.com'>info@conceptprint.com</a>
                  </span>
                </span>
                <span className='grid grid-cols-[20fr_80fr] items-center justify-center'>
                  <span className='material-symbols-outlined mr-[5px]'>call</span>
                  <span className='Type-XL-Medium ml-[2px]'>845-353-4040</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className='flex h-full flex-col items-center justify-center'>
          <ul className='hidden gap-x-3 lg:flex'>
            {socials.map((social, i) => (
              <li key={social.href}>
                <a href={social.href} target='_blank' rel='noreferrer'>
                  <Image
                    src={social.image}
                    alt={social.alt}
                    id={social.id}
                    unoptimized
                    height={25}
                    width={25}
                    className='h-[25px] text-concept-print-white'
                  />
                </a>
              </li>
            ))}
          </ul>
          {/* <p className='Type-Base-Medium mb-[17px] hidden text-concept-print-white lg:block'>
            &copy;Concept Print 2023. All rights reserved.
          </p> */}
        </div>
        {/* 
        <ul className='mb-2 flex justify-center gap-x-5 lg:mb-2.5 lg:flex lg:gap-x-3'>
          <li>
            <Image src={visa} alt='Visa' unoptimized />
          </li>
          <li>
            <Image src={amex} alt='Amex' unoptimized />
          </li>
          <li>
            <Image src={discover} alt='Discover' unoptimized />
          </li>
          <li>
            <Image src={mastercard} alt='Mastercard' unoptimized />
          </li>
        </ul>
        <p className='Type-Small-Medium text-center lg:hidden'>
          &copy;Templi 2023. All rights reserved.
        </p> */}
      </div>
    </footer>
  )
}
