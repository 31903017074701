import { Image } from '@graphcommerce/image'
import svgLogoWhite from '../../assets/images/concept-logo-white.svg'

export function Logo({ mode = 'purple' }: { mode?: 'white' | 'purple' }) {
  return (
    <div className='w-[30px]'>
      <Image alt='Concept Print Logo' src={svgLogoWhite} className='scale-[120%]' unoptimized />
    </div>
  )
}
