/* eslint-disable */
import * as Types from '@graphcommerce/graphql-mesh/.mesh';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

export const CompanyAccountDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"CompanyAccount"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"customer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"bss_is_company_account"}}]}},{"kind":"Field","name":{"kind":"Name","value":"bssSubUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"role"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"role_name"}},{"kind":"Field","name":{"kind":"Name","value":"role_type"}}]}}]}}]}}]} as unknown as DocumentNode<CompanyAccountQuery, CompanyAccountQueryVariables>;
export type CompanyAccountQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CompanyAccountQuery = { customer?: { bss_is_company_account?: boolean | null } | null, bssSubUser?: { role?: { role_name?: string | null, role_type?: string | null } | null } | null };
