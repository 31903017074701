import Link from 'next/link'

export interface TextLinks {
  label: string
  href: string
}

export function NavigationFooterLinks() {
  const links: Array<TextLinks> = [
    {
      label: 'Blog',
      href: '/blog',
    },
    {
      label: 'Instagram',
      href: 'https://www.instagram.com/templi/',
    },
    {
      label: 'Tik Tok',
      href: 'https://www.tiktok.com/@templi_packaging',
    },
  ]
  return (
    <div className='mb-5 flex flex-col gap-5 px-5'>
      <hr className='text-50-grey' />
      <div className='Type-XL-Regular mx-5 flex flex-row items-center justify-between capitalize text-70-grey '>
        {links.map((item) => (
          <Link href={item.href} className='hover:text-material-ui-blue'>
            {item.label}
          </Link>
        ))}
      </div>
    </div>
  )
}
