import { Image } from '@graphcommerce/image'

type ResponsiveImageGridItemProps = {
  src: string
  alt?: string
  aspect?: string
  className?: string
}

export function ResponsiveImageGridItem(props: ResponsiveImageGridItemProps) {
  const { src, alt, aspect = '4/3', className } = props
  const aspectStyle = `aspect-[${aspect}]`
  return (
    <div
      className={`block h-full w-full items-center justify-center overflow-hidden rounded-[4px] ${aspectStyle} ${className}`}
      style={{ aspectRatio: aspect }}
    >
      <Image
        className='h-full max-h-none w-full max-w-none rounded-[4px] object-cover delay-100 duration-300 ease-in-out hover:scale-125'
        src={src}
        layout='fill'
        alt={alt}
      />
    </div>
  )
}
