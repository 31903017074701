import { Skeleton } from '@mui/material'

export type CartItemSkeletonProps = {
  isMiniCartItem?: boolean
}
export function CartItemSkeleton(props: CartItemSkeletonProps) {
  const { isMiniCartItem } = props
  return (
    <div className='flex w-full flex-col items-center justify-center gap-1'>
      <div
        className={`grid min-h-[180px] w-full ${
          isMiniCartItem
            ? 'grid-cols-[35fr_65fr]'
            : 'grid-cols-[35fr_65fr] md:grid-cols-[15fr_75fr]'
        } gap-4`}
      >
        <Skeleton
          variant='rounded'
          sx={{ height: 'auto', width: '100%', borderRadius: '6px' }}
          animation='pulse'
        />
        <div className='flex flex-col'>
          <div className='max-w-[60%]'>
            <Skeleton variant='text' sx={{ fontSize: '1rem', width: '100%' }} animation='pulse' />
            <Skeleton variant='text' sx={{ fontSize: '1rem', width: '100%' }} animation='pulse' />
          </div>
          <Skeleton
            variant='rounded'
            height={32}
            sx={{ width: '80%', borderRadius: '6px', marginTop: '30px' }}
            animation='pulse'
          />
          <Skeleton
            variant='text'
            sx={{ fontSize: '0.5rem', width: '80%', marginTop: '4px' }}
            animation='pulse'
          />
        </div>
      </div>
    </div>
  )
}
