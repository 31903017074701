import { useQuery } from '@graphcommerce/graphql'
import { ApolloCustomerErrorSnackbar } from '@graphcommerce/magento-customer/components/ApolloCustomerError/ApolloCustomerErrorSnackbar'
import { SignUpConfirmDocument } from '@graphcommerce/magento-customer/components/SignUpForm/SignUpConfirm.gql'
import { graphqlErrorByCategory } from '@graphcommerce/magento-graphql'
import { StoreConfigDocument } from '@graphcommerce/magento-store'
import { FormActions } from '@graphcommerce/next-ui'
import { useFormGqlMutation, useFormPersist } from '@graphcommerce/react-hook-form'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { Alert, MenuItem } from '@mui/material'
import { useContext } from 'react'
import { StandardButton } from '../../Button/ButtonStyles'
import { StandardDropdown } from '../../Dropdown/StandardDropdown'
import { fbqCompleteRegistration } from '../../FacebookPixel/events/fbqCompleteRegistration'
import { Message } from '../../Message'
import { globalContext } from '../../NextUi/globalContext'
import { StandardTextField } from '../../TextInput/TextFieldStyles'
import { NameFields } from '../NameFields/NameFields'
import { SignUpDocument, SignUpMutation, SignUpMutationVariables } from './SignUp.gql'

type SignUpFormProps = { email: string }

const requireEmailValidation = process.env.CUSTOMER_REQUIRE_EMAIL_CONFIRMATION === '1'

export function SignUpForm(props: SignUpFormProps) {
  const { email } = props

  const storeConfig = useQuery(StoreConfigDocument).data?.storeConfig

  const { drawer, setRedirectAfterSignIn } = useContext(globalContext)

  const Mutation = requireEmailValidation ? SignUpConfirmDocument : SignUpDocument

  const form = useFormGqlMutation<
    SignUpMutation,
    SignUpMutationVariables & { confirmPassword?: string }
  >(
    Mutation,
    {
      defaultValues: { email, prefix: '', companyIndustry: 'retail' },
      onBeforeSubmit: (values) => ({ ...values, email }),
      onComplete: () => {
        fbqCompleteRegistration()
      },
    },
    { errorPolicy: 'all' },
  )

  const { register, handleSubmit, required, watch, formState, error } = form
  const [remainingError, inputError] = graphqlErrorByCategory({ category: 'graphql-input', error })

  const submitHandler = handleSubmit(() => {
    if (drawer.id === 'auth' && typeof drawer.params?.redirectAfterSignIn === 'string') {
      setRedirectAfterSignIn(drawer.params.redirectAfterSignIn)
    }
  })
  const watchPassword = watch('password')

  useFormPersist({ form, name: 'SignUp', exclude: ['password', 'confirmPassword'] })

  if (requireEmailValidation && form.formState.isSubmitSuccessful) {
    return (
      <Alert>
        <Trans id='Please check your inbox to validate your email ({email})' values={{ email }} />
      </Alert>
    )
  }
  const industryTypeOptions = [
    { value: 'retail', label: 'Retail' },
    { value: 'ecommerce', label: 'Ecommerce' },
    { value: 'cafe_bakery', label: 'Cafe / Bakery' },
    { value: 'bar_restaurant', label: 'Bar + Restaurant' },
    { value: 'hotel_resort', label: 'Hotel / Resort' },
    { value: 'fast_casual_restaurant', label: 'Fast Casual Restaurant' },
    { value: 'dispensery', label: 'Dispensery' },
    { value: 'brewery_winery', label: 'Brewery / Winery' },
    { value: 'creative_agency', label: 'Creative Agency' },
  ]

  const menuItems = industryTypeOptions.map((option) => (
    <MenuItem value={option.value}>{option.label}</MenuItem>
  ))

  return (
    <form onSubmit={submitHandler} noValidate>
      <div className='mt-6 grid min-w-[260px] items-center justify-center gap-6'>
        <StandardTextField
          label={<Trans id='Password' />}
          variant='outlined'
          color='primary'
          size='small'
          key='password'
          type='password'
          error={!!formState.errors.password || !!inputError}
          autoComplete='current-password'
          id='current-password'
          required={required.password}
          {...register('password', {
            required: required.password,
            minLength: {
              value: Number(storeConfig?.minimum_password_length ?? 8),
              message: i18n._(/* i18n */ 'Password must have at least 8 characters'),
            },
          })}
          className='min-w-[260px]'
          sx={{
            minWidth: '260px',
          }}
        />
        <StandardTextField
          label={<Trans id='Re-enter password' />}
          variant='outlined'
          color='primary'
          size='small'
          type='password'
          error={!!formState.errors.confirmPassword}
          autoComplete='new-password'
          required
          {...register('confirmPassword', {
            required: true,
            validate: (value) =>
              value === watchPassword || i18n._(/* i18n */ "Passwords don't match"),
          })}
          helperText={formState.errors.confirmPassword?.message}
          disabled={formState.isSubmitting}
          className='min-w-[260px]'
          sx={{
            minWidth: '260px',
          }}
        />
        <StandardTextField
          label={<Trans id='Company Name' />}
          variant='outlined'
          color='primary'
          size='small'
          type='text'
          error={!!formState.errors.firstname}
          required
          {...register('companyName', {
            required: true,
          })}
          className='min-w-[260px]'
          sx={{
            minWidth: '260px',
          }}
        />
      </div>
      <NameFields form={form} required prefix columnDisplay className='my-6' />
      <StandardDropdown
        value={watch('companyIndustry')?.toString() || ''}
        className='w-[260px] max-w-[260px]'
        {...register('companyIndustry')}
        onChange={(option) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if (option?.target?.value)
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            form.setValue('companyIndustry', option?.target?.value?.toString())
        }}
        label='Industry Type'
        options={menuItems}
        sx={{
          width: '260px',
          minWidth: '260px',
        }}
      />
      {inputError && <Message content={inputError.message} />}
      <ApolloCustomerErrorSnackbar error={remainingError} />

      <FormActions>
        <StandardButton
          type='submit'
          id='create-account'
          loading={formState.isSubmitting}
          className='w-[260px] max-w-[260px] bg-material-ui-blue uppercase'
          variant='contained'
          size='small'
        >
          <Trans id='Create account!' />
        </StandardButton>
      </FormActions>
    </form>
  )
}
