import { CustomerDocument } from '@graphcommerce/magento-customer'
import { useCustomerQuery } from '@graphcommerce/magento-customer/hooks'
import { FormActions, FormRow, LayoutTitle, extendableComponent } from '@graphcommerce/next-ui'
import { Trans } from '@lingui/react'
import { Box, Typography } from '@mui/material'
import router from 'next/router'
import { useState } from 'react'
import { useFormIsEmailAvailable } from '../../../hooks/Customer/useFormIsEmailAvailable'
import { SubUserSignInForm } from '../../Account/SubUserForm/SubUserSignInForm'
import { Button } from '../../Button'
import { StandardButton } from '../../Button/ButtonStyles'
import { ForgotPasswordForm } from '../../Customer/ForgotPasswordForm/ForgotPasswordForm'
import { SignInForm } from '../../Customer/SignInForm/SignInForm'
import { SignInSocial } from '../../Customer/SignInSocial/SignInSocial'
import { SignUpForm } from '../../Customer/SignUpForm/SignUpForm'
import { Icon } from '../../Layout/Icon'
import { SlideinModalHeader } from '../../Layout/SlideinModalHeader'

export type AccountSignInUpFormProps = { onClose: () => unknown }

const parts = ['root', 'titleContainer'] as const
const { classes } = extendableComponent('AccountSignInUpForm', parts)

export function AccountSignInUpForm(props: AccountSignInUpFormProps) {
  const { onClose } = props
  const customerQuery = useCustomerQuery(CustomerDocument)

  const { email } = customerQuery.data?.customer ?? {}
  const { mode, setMode, form, autoSubmitting, isSubUser } = useFormIsEmailAvailable({
    email,
  })
  const { formState, register, watch } = form
  const disableFields = formState.isSubmitting && !autoSubmitting
  const [hasSocials, setHasSocials] = useState<boolean>(false)

  const handleCloseForgotPassword = () => {
    if (mode === 'forgot') {
      setMode('signin')
    }
    onClose()
  }

  return (
    <div>
      {/* {mode === 'email' && (
        <SlideinModalHeader
          title='Sign in or Create account'
          onClose={onClose}
          className='mb-[80px]'
        />
      )} */}
      {(mode === 'signin' || mode === 'email') && (
        <SlideinModalHeader title='Log in' onClose={onClose} className='mb-[80px]' />
      )}

      {mode === 'forgot' && (
        <SlideinModalHeader
          title='Forgot Password'
          onClose={handleCloseForgotPassword}
          className='mb-[80px]'
        />
      )}

      {mode === 'signup' && (
        <>
          <SlideinModalHeader
            title='Create an Account'
            onClose={onClose}
            withHorizontalMargin
            className='mb-4'
          />
          <div className='mb-4 flex items-center justify-center'>
            <StandardButton
              type='submit'
              loading={formState.isSubmitting}
              className='w-[260px] max-w-[260px] bg-material-ui-blue uppercase'
              variant='contained'
              size='small'
              onClick={() => setMode('email')}
            >
              <Trans id='Go Back' />
            </StandardButton>
          </div>
        </>
      )}

      {mode === 'signedin' && (
        <div>
          <FormActions>
            <Button
              onClick={() => router.back()}
              color='blue'
              label={<Trans id='Continue shopping' />}
            />
          </FormActions>
        </div>
      )}

      {mode === 'session-expired' && (
        <Box className={classes.titleContainer}>
          <LayoutTitle variant='h2' gutterBottom={false}>
            <Trans id='Your session is expired' />
          </LayoutTitle>
          <Typography variant='h6' align='center'>
            <Trans id='Log in to continue shopping' />
          </Typography>
        </Box>
      )}
      {mode !== 'signedin' && mode !== 'forgot' && (
        <div className='mb-[54px] px-[20px] md:px-[50px]'>
          <SignInSocial />
          {!hasSocials && (
            <div className='mt-4 flex items-center'>
              <div className='flex-grow border-t border-20-grey ' />
              <span className='Type-XL-Regular mx-3 text-50-grey'>OR</span>
              <div className='flex-grow border-t border-20-grey ' />
            </div>
          )}
        </div>
      )}

      {mode !== 'signedin' && mode !== 'forgot' && !isSubUser && (
        <div className='px-[20px] md:px-[50px]'>
          <SignInForm
            email={watch('email')}
            register={register}
            switchModeToForgotPass={() => setMode('forgot')}
            hasSocials={hasSocials}
            setHaSocials={setHasSocials}
            disableFields={disableFields}
            mode={mode}
            switchModeToSignUp={() => setMode('signup')}
          />
        </div>
      )}

      {mode === 'signin' && isSubUser && (
        <div className='px-[20px] md:px-[50px]'>
          <SubUserSignInForm
            email={watch('email')}
            register={register}
            switchModeToForgotPass={() => setMode('forgot')}
            hasSocials={hasSocials}
            setHaSocials={setHasSocials}
            disableFields={disableFields}
            mode={mode}
          />
        </div>
      )}

      {mode === 'signup' && (
        <div className='px-[20px] md:px-[50px]'>
          <SignUpForm email={watch('email')} />
        </div>
      )}

      {mode === 'forgot' && (
        <div className='px-[20px] md:px-[50px]'>
          <ForgotPasswordForm email={watch('email')} />
        </div>
      )}
    </div>
  )
}
