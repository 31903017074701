/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useFormGqlMutation } from '@graphcommerce/ecommerce-ui'
import { useApolloClient } from '@graphcommerce/graphql'
import { SignOutFormDocument } from '@graphcommerce/magento-customer/components/SignOutForm/SignOutForm.gql'
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { Tooltip } from '@mui/material'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { Dispatch, SetStateAction, useState } from 'react'
import settingsIcon from '../../../assets/images/settings.svg'
import signOutIcon from '../../../assets/images/signOut.svg'
import { Spinner } from '../../Spinner'

type AccountMenuProps = {
  accountName: string
  accountEmail: string | null | undefined
  setShowMenu: Dispatch<SetStateAction<boolean>>
  showMenu: boolean
}

export function AccountMenu(props: AccountMenuProps) {
  const { accountName, accountEmail, setShowMenu, showMenu } = props
  const [isSigningOut, setIsSigningOut] = useState<boolean>(false)
  const [isNavigating, setIsNavigating] = useState<boolean>(false)

  const router = useRouter()
  const handleNavigateToAccountSettings = async () => {
    setIsNavigating(true)
    await router.push('/account?navigateTo=2')
    setShowMenu(false)
    setIsNavigating(false)
  }

  const client = useApolloClient()

  const { handleSubmit, formState, error } = useFormGqlMutation(
    SignOutFormDocument,
    {
      onComplete: async () => {
        await client.clearStore()
        // We could not make refetch the session query, so this is the best option
        router.reload()
        setIsSigningOut(false)
      },
    },
    { errorPolicy: 'all' },
  )
  const submitHandler = handleSubmit(() => {})

  return (
    <>
      {showMenu && (
        <div
          className='absolute right-0 top-[46px] z-10 m-auto flex w-[230px] min-w-[230px] flex-col rounded-sm border-[1px] border-t-0 border-20-grey bg-[#FFFFFF] shadow-md'
          onMouseLeave={() => setShowMenu(false)}
        >
          <div className='flex h-[68px] min-h-[68px] flex-col items-start justify-center'>
            {accountName && <span className='Type-XL-Regular mx-5 '>{accountName ?? ''}</span>}
            {accountEmail && (
              <Tooltip title={accountEmail ?? ''} placement='right-end'>
                <span className='Type-Large-Regular mx-5 max-w-[200px] truncate text-70-grey'>
                  {accountEmail ?? ''}
                </span>
              </Tooltip>
            )}
          </div>
          <div
            onClick={async () => handleNavigateToAccountSettings()}
            className='flex h-[54px] min-h-[54px] w-full cursor-pointer items-center justify-between border-t-[1px] border-t-20-grey hover:bg-[#6133941A]'
          >
            <div className='flex items-center justify-start gap-[12px] '>
              <Image
                src={settingsIcon}
                height={24}
                width={24}
                unoptimized
                alt='Settings'
                className='ml-5'
              />
              <span className='Type-XL-Regular mr-5 whitespace-nowrap '>Account settings</span>
            </div>
            {isNavigating && <Spinner className='mr-5' />}
          </div>
          <div className='cursor-point flex h-[54px] min-h-[54px] w-full items-center border-t-[1px] border-t-20-grey hover:bg-[#6133941A]'>
            <form
              onSubmit={submitHandler}
              noValidate
              className='flex w-full items-center justify-between'
            >
              <button
                type='submit'
                className='flex w-full items-center justify-start gap-[12px]'
                onClick={() => setIsSigningOut(true)}
              >
                <Image
                  src={signOutIcon}
                  height={24}
                  width={24}
                  unoptimized
                  alt='Settings'
                  className='ml-5'
                />
                <span className='Type-XL-Regular mr-5'>Sign out</span>
              </button>
              {isSigningOut && <Spinner className='mr-5' />}
            </form>
          </div>
        </div>
      )}
    </>
  )
}
